jQuery(document).ready(function($){

    function tense_equalize_slides($slides) {
        if (Foundation.MediaQuery.atLeast('medium')) {
            var max_height = 0;
            $slides.each(function() {
                max_height = Math.max($(this).height(), max_height);
            });
            $slides.height(max_height);
        } else {
            $slides.css('height', 'auto');
        }
    }

    $('[data-slider-equalize]').on('init reInit', function(event, slick) {
        tense_equalize_slides(slick.$slides);
    });

    function tense_resize_handler() {
        $tense_sliders.each(function() {
            var slick = $(this).slick('getSlick');
            tense_equalize_slides(slick.$slides);
        });
    }

    var $tense_sliders = $('[data-slider-equalize]');

    if ($tense_sliders.length) {
        var resize_timer;

        $(window).resize(function() {
            clearTimeout(resize_timer);

            resize_timer = setTimeout(tense_resize_handler, 200);
        });
    }

    $('.slider-photo').on('init', function(event, slick) {
        $(".slider-photo [data-slick-index='"+slick.currentSlide+"'] video").each(function() { this.play() });
    });

    $('.slider-photo, .slider-reviews').slick({
        dots: false,
        speed: 300,
        infinite: true,
        autoplay: true,
        fade: true,
        autoplaySpeed: 5000,
        cssEase: 'ease-out',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
    });

    $('.slider-photo').on('afterChange', function(event, slick, currentSlide) {
        $(".slider-photo [data-slick-index='"+currentSlide+"'] video").each(function() { this.play() });
    });

    $('.slider-photo').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        $(".slider-photo [data-slick-index='"+currentSlide+"'] video").each(function() { this.pause() });
    });

    $('.slider-logo').slick({
        dots: false,
        speed: 300,
        infinite: true,
        autoplay: true,
        fade: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplaySpeed: 5000,
        cssEase: 'ease-out',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ],
    });


});
